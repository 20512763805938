/* src/Styles/WhatsAppIcon.css */
.whatsapp-icon {
    position: fixed;
    bottom: 70px;
    right: 20px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    /* font-size: 24px; */
    z-index: 1000;
    text-decoration: none;
}

.whatsapp-icon:hover {
    background-color: #20b358;
    text-decoration: none;
}
