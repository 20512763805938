/* Description.css */

.description-container {
    margin: 20px auto;
    padding: 20px;
    max-width: 1200px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 5px;
    position: relative;
    /* Ensure that the pseudo-element is positioned relative to this container */
}

.description-text {
    font-size: 16px;
    font-family: 'Times New Roman', serif;
    color: #333;
    line-height: 1.5;
    margin-bottom: 20px;
    /* Add space below the text */
}

.description-container::after {
    content: "";
    display: block;
    width: 100%;
    border-top: 1px solid #D3D3D3;
    /* Light gray line */
    margin-top: 20px;
    /* Add space above the line */
}
.prouductcard::after{
    content: "";
    display: block;
    width: 100%;
    border-top: 1px solid #D3D3D3;
    /* Light gray line */
    margin-top: 20px;
    /* Add space above the line */
}
.history::after{
    content: "";
    display: block;
    width: 205%;
    border-top: 1px solid #D3D3D3;
    /* Light gray line */
    margin-top: 20px;
    /* Add space above the line */
}
.bloghome::after{
    content: "";
    display: block;
    width: 205%;
    border-top: 1px solid #D3D3D3;
    /* Light gray line */
    margin-top: 20px;
    /* Add space above the line */
}
.blogline{
    content: "";
    display: block;
    width: 205%;
    border-top: 1px solid #D3D3D3;
    /* Light gray line */
    margin-top: 20px;
    /* Add space above the line */
}
.cartline::after{
    content: "";
    display: block;
    width: 100%;
    border-top: 1px solid #D3D3D3;
    /* Light gray line */
    margin-top: 20px;
    /* Add space above the line */
}

.line-hover::after {
    content: "";
    display: block;
    width: 54px; /* Default width for desktop */
    height: 1px;
    background-color: black;
    transition: width 0.3s ease;
    margin-left: 34px; /* Spacing adjustment for desktop */
}

/* Make it centered on mobile view */
@media (max-width: 640px) {
    .line-hover::after {
        margin-left: 152px;
        width: 15%; /* Full width underline on mobile */
    }
}


  .line-hover:hover::after {
    width: 0;
    transition: width 0.3s ease;
  }
  .custom-checkbox:checked {
    background-color: #ccb898;
    border-color: #ccb898;
}
.custom-checkbox {
    accent-color: #ccb898;
}

.flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .product-info {
    width: 180px; /* Consistent width for names */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  input {
    text-align: center;
    width: 50px;
  }
  
  button {
    min-width: 32px; /* Ensure buttons have equal size */
  }
  

  
  
  