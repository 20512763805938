@font-face {
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    src: local('Hubballi'), url('https://fonts.cdnfonts.com/s/93158/Hubballi-Regular.woff') format('woff');
}

.blog-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
    margin-top: 15px;
}

.blog-header {
    text-align: center;
    margin-bottom: 40px;
}

.blog-header h1 {
    font-size: 22px;
    font-family: 'Hubballi', sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    margin-left: 35px;
    letter-spacing: 2px;
}

.blog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.blog-article-large {
    position: relative;
    width: 1250px;
    height: 500px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

.blog-image-large {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-text-overlay {
    position: absolute;
    font-family: 'Hubballi', sans-serif;
    letter-spacing: 10px;
    line-height: 200%;
    bottom: 150px;
    left: 20px;
    color: white;
    margin: 20px;
}

.blog-text-overlay h2 {
    font-size: 24px;
    margin: 0 0 10px 0;
}

.blog-article-small-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    margin-top: 40px;
}

.blog-article-small {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    width: 400px;
    /* Set a consistent width for all small articles */
    align-items: center;
    box-sizing: border-box;
    margin: 0 auto;
    /* Center align the small articles within the container */
}

/* .blog-image-small {
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-bottom: 10px;
} */

.blog-article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.blog-article-content h2 {
    font-size: 20px;
    margin: 10px 0;
    font-family: 'Hubballi', sans-serif;
    font-weight: 580;
    letter-spacing: 5px;
    color: #000000;
    text-align: left;
    position: static;

}

.blog-article-content p {
    font-size: 16px;
    margin: 0 0 10px 0;
    font-family: 'Georgia', 'Times New Roman', serif;
    line-height: 1.5;

    font-weight: normal;
    letter-spacing: 2px;
    text-align: left;
    color: #4b4646;
}

.read-more {
    color: #494848;
    font-weight: lighter;
    text-align: left;
    font-size: 12px;
}

.read-more:hover {
    text-decoration: underline;
}

.read-more-button {
    display: inline-block;
    margin-top: 50px;
    padding: 10px 20px;
    background-color: white;
    color: rgb(68, 66, 66);
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
}

.placeholder-black::placeholder {
    color: black;
  }
  

  .blog-article-small {
    position: relative; /* Ensure positioning for the image */
    overflow: hidden;  /* Hide overflow to prevent image from spilling out */
  }
  
  .blog-image-small {
    transition: transform 10s ease; /* Smooth transition for the zoom effect */
    width: 100%;
    height: 220px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .blog-article-small:hover .blog-image-small {
    transform: scale(1.1); /* Scale image to 110% on hover */
  }
  

  /* MEDIA QUERIES */



/* Tablets (768px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .blog-article-large {
        height: 400px;
    }

    .blog-article-small {
        max-width: 45%; /* Two articles side by side */
    }

    .blog-image-small {
        height: 200px;
    }
}

/* Desktop (above 1024px) */
/* MEDIA QUERIES */

/* Mobile Devices (up to 768px) */
@media (max-width: 640px) {
    /* .blog-article-large {
        height: 300px;
    } */

    .blog-text-overlay h2 {
        font-size: 15px;
        max-width: 30%;
        margin-left: 70px;
    }
    

    .blog-article-small {
        max-width: 90%; /* Use 90% width for small screens */
    }

    .blog-image-small {
        height: 180px;
    }

    .blog-article-content h2 {
        font-size: 18px;
    }

    .read-more-button {
        padding: 8px 16px;
        font-size: 14px;
        margin-left: 70px;
    }
}

/* Tablets (768px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .blog-article-large {
        height: 400px;
    }

    .blog-article-small {
        max-width: 45%; /* Two articles side by side */
    }

    .blog-image-small {
        height: 200px;
    }
}

/* Desktop (above 1024px) */
/* @media (min-width: 1025px) {
    .blog-article-large {
        height: 500px;
    }

    .blog-article-small {
        max-width: 30%; 
    }
} */