.shop-title h2 {
    font-size: 20px;
    font-family: 'Hubballi', sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    margin-top: -25px;
    margin-left: 35px;
    letter-spacing: 2px;
}
 .description_Shop {
    font-size: 15px;
    font-family: 'Lateef';
    text-align: center;
    line-height: 2;
    /* Increased from 1.6 */
    margin-bottom: 0px;
}