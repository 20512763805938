/* Sliders.css */

.cover-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.cover {
    /* position: relative; */
    width: 1440px;
    height: 489px;
    border-radius: 2px;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    margin-top: -9px;
    /* Ensures smooth transition */
}

.fade-in {
    opacity: 1;
    /* Fades in to fully opaque */
}