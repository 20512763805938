@font-face {
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    src: local('Hubballi'), url('https://fonts.cdnfonts.com/s/93158/Hubballi-Regular.woff') format('woff');
}

.header-container {
    background-color: #C5B59E;
    color: rgb(0, 0, 0);
    padding: 10px 0;
    text-align: center;
    position: relative;
}

.header-content {
    margin-bottom: 5px;
}

.header-title {
    margin: 0;
    font-size: 20px;
    font-family: 'Hubballi', sans-serif;
    font-weight: normal;
    letter-spacing: 1px;
}

.selectors-container {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 10px;
}

/* Styling for currency and language selectors */
.currency-selector,
.language-selector {
    margin: 0;
}

#currency,
#language {
    padding: 5px 10px;
    font-size: 14px;
    font-family: 'Hubballi', sans-serif;
    border: 1px solid #C5B59E;
    background-color: white;
    color: black;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

#currency:hover,
#language:hover {
    background-color: #C5B59E;
    color: white;
    border-color: rgb(255, 255, 255);
}

#currency:focus,
#language:focus {
    border-color: #ffffff;
}
@font-face {
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    src: local('Hubballi'), url('https://fonts.cdnfonts.com/s/93158/Hubballi-Regular.woff') format('woff');
}

.header-container {
    background-color: #C5B59E;
    color: rgb(0, 0, 0);
    padding: 10px 0;
    text-align: center;
    position: relative;
}

.header-content {
    margin-bottom: 5px;
}

.header-title {
    margin: 0;
    font-size: 20px;
    font-family: 'Hubballi', sans-serif;
    font-weight: normal;
    letter-spacing: 1px;
}

.selectors-container {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 10px;
}

/* Styling for currency and language selectors */
.currency-selector,
.language-selector {
    margin: 0;
}

#currency,
#language {
    padding: 5px 10px;
    font-size: 14px;
    font-family: 'Hubballi', sans-serif;
    border: 1px solid #948878;
    background-color: transparent;
    color: black;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    height: 44px;
  width: 100px;
}

#currency:hover,
#language:hover {
    color: #948878;
    border-color: #948878;
}

#currency:focus,
#language:focus {
    border-color: #ffffff;
}

@media (max-width: 768px) {
    .header-container {
        flex-direction: column;
    }

    .header-content {
        margin-bottom: 50px;
    }

    .header-title {
        font-size: 1.2rem;
    }

    .selectors-container {
        margin-top: 20px;
    }

    .currency-selector,
    .language-selector {
        width: 100%;
    }
}

@media (max-width: 640px) {
    .header-title {
        font-size: 16px;
        padding: 10px;
    }

    .selectors-container {
        right: 90px;
        top: auto;
        bottom: 10px;
        transform: none;
    }

    #currency,
    #language {
        font-size: 12px;
        padding: 4px 8px;
    }
}

@media (min-width: 641px) and (max-width: 1024px) {
    .header-title {
        font-size: 10px;
        margin-bottom: 20px;
    }
    .header-content {
        margin-bottom: 30px;
    }
    .selectors-container {
        right: 15px;
    }

    #currency,
    #language {
        font-size: 13px;
        padding: 4px 9px;
    }
}

@media (min-width: 1025px) {
    .header-title {
        font-size: 20px;
    }

    .selectors-container {
        right: 30px;
    }

    #currency,
    #language {
        font-size: 15px;
        padding: 6px 12px;
    }
}

.selector {
    background-color: #ff0000;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
/* Responsive Design */
/* Small screens (mobile) */
/* @media (max-width: 640px) {
    .header-title {
        font-size: 16px;
    }

    .selectors-container {
        right: 10px;
        top: auto;
        bottom: 10px;
        transform: none;
    }

    #currency,
    #language {
        font-size: 12px;
        padding: 4px 8px;
    }
} */

/* Medium screens (tablets) */
/* @media (min-width: 641px) and (max-width: 1024px) {
    .header-title {
        font-size: 18px;
    }

    .selectors-container {
        right: 15px;
    }

    #currency,
    #language {
        font-size: 13px;
        padding: 4px 9px;
    }
} */

/* Large screens (desktops) */
/* @media (min-width: 1025px) {
    .header-title {
        font-size: 24px;
    }

    .selectors-container {
        right: 30px;
    }

    #currency,
    #language {
        font-size: 15px;
        padding: 6px 12px;
    }
} */

select {
    font-size: 20px !important; /* `!important` should be inside the declaration */
    width: 100%; /* Optional: Ensure full width */
    
  }
  
