/* src/Styles/Footer.css */
@font-face {
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    src: local('Hubballi'), url('https://fonts.cdnfonts.com/s/93158/Hubballi-Regular.woff') format('woff');
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fffefe;
        color: #363535;
    padding: 20px;
    width: 100%;
    position: static;
    bottom: 0;
    left: 0;
    z-index: 1000;
    border-top: 1px solid #e7e7e7;
    /* Added gray line at the top of the footer */
}

.footer-columns {
    font-family: 'Times New Roman', serif;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    /* Added space between sections */
}

.footer-column {
    flex: 1;
    margin: 0 20px;
}

.footer-column h4 {
    font-family: 'Hubballi', sans-serif;
    font-weight: normal;
    font-size: 20px;
    letter-spacing: 2px;
    /* Changed font for the titles */
    margin-bottom: 30px;
    /* Increased space below titles */
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column ul li {
    margin-bottom: 20px;
    /* Increased space between list items */
}

.footer-column ul li a {
    text-decoration: none;
    color: #363535;
}

.footer-column p {
    margin: 10px 0;
    /* Increased space around paragraphs */
}

.social-icons {
    display: flex;
    gap: 20px;
}

/* .social-icons a {
    text-decoration: none;
        color: #363535;
}

.logo img {
    margin-top: 10px;
    width: 50px;
    transform: rotate(2.9deg);
} */

/* .footer-bottom {
    display: flex;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    border-top: 1px solid #e7e7e7;
    padding-top: 5px;
} */

/* .payment-icons img {
    width: 40px;
    margin-right: 10px;
} */