/* src/styles/Aboutuspage.css */
@font-face {
  font-family: "Hubballi";
  font-style: normal;
  font-weight: 400;
  src: local("Hubballi"),
    url("https://fonts.cdnfonts.com/s/93158/Hubballi-Regular.woff")
      format("woff");
}

body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

.aboutus-container {
  max-width: 1000px;
  /* Increased from 800px */
  margin: 20px auto;
  padding: 0 20px;
}

.aboutus-section {
  margin-bottom: 20px;
  padding: 30px;
  /* Increased from 20px */
  border-radius: 8px;
}

.aboutus-section h2 {
  font-size: 20px;
  font-family: "Hubballi", sans-serif;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  margin-top: -25px;
  margin-left: 35px;
  letter-spacing: 2px;
}

.aboutus-section p {
  font-size: 15px;
  font-family: "Lateef";
  text-align: center;
  line-height: 2;
  /* Increased from 1.6 */
  margin-bottom: 0px;
}

.aboutus-section img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: fit-content;
  margin-top: 20px;
}
.aboutus-section img.aboutus-image-philosophy {
  width: 1000px;
  /* Set the width */
  height: 450px;
  /* Set the height */
  object-fit: cover;
  /* Ensure the image covers the entire area without distortion */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
