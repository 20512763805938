@font-face {
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    src: local('Hubballi'), url('https://fonts.cdnfonts.com/s/93158/Hubballi-Regular.woff') format('woff');
}

.login-container {
    width: 90%; /* Adjust width to be responsive */
    max-width: 500px;
    margin: 0 auto;
    padding: 29px;
    text-align: center;
}

.login-title {
    font-family: 'Hubballi', sans-serif;
    font-size: 2.2rem; /* Responsive font size */
    font-weight: normal;
    letter-spacing: 3px;
    margin-bottom: 30px; /* Reduced margin for smaller screens */
}

.login-subtitle {
    font-family: 'Hubballi', sans-serif;
    font-size: 1.2rem; /* Responsive font size */
    margin-bottom: 20px; /* Reduced margin for smaller screens */
    font-weight: normal;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border: 0.5px solid #ccc;
    border-radius: 4px; /* Rounded corners for inputs */
    font-size: 1rem; /* Responsive font size */
    font-family: Georgia, serif;
    position: relative;
}

.password-container {
    position: relative;
}

.forgot-password {
    font-size: 0.9rem; /* Responsive font size */
    font-family: Georgia, serif;
    font-weight: lighter;
    color: #666;
    text-decoration: none;
    position: absolute;
    right: 10px;
    top: 100%;
    transform: translateY(-50%);
}

.password-toggle {
    font-family: Georgia, serif;
    font-weight: lighter;
    color: #666;
    text-decoration: none;
    position: absolute;
    right: 10px;
    top: 37%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer; /* Add cursor pointer */
}

.login-button {
    padding: 15px;
    font-family: 'Hubballi', sans-serif;
    font-size: 1.1rem; /* Responsive font size */
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px; /* Rounded corners for buttons */
    cursor: pointer;
    letter-spacing: 1px; /* Reduced letter spacing */
    margin-top: 20px; /* Reduced margin for smaller screens */
}

.create-account {
    font-size: 0.9rem; /* Responsive font size */
    font-family: Arial, serif;
    font-weight: lighter;
    margin-top: 20px; /* Reduced margin for smaller screens */
}

.create-account a {
    color: #000;
    text-decoration: none;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .login-title {
        font-size: 1.8rem;
    }

    .login-subtitle {
        font-size: 1rem;
    }

    .login-button {
        font-size: 1rem;
    }

    .create-account {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .login-title {
        font-size: 1.5rem;
    }

    .login-subtitle {
        font-size: 0.9rem;
    }

    .login-button {
        font-size: 0.9rem;
        padding: 10px;
    }

    .create-account {
        font-size: 0.7rem;
    }
}
