
.description_product{
    font-size: 15px;
    font-family: 'Lateef';
  
    line-height: 2;
    /* Increased from 1.6 */
    margin-bottom: 0px;
}
.favorite-title h2 {
    font-size: 20px;
    font-family: 'Hubballi', sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    margin-top: -25px;
    margin-left: 35px;
    letter-spacing: 2px;
}
.zoom-image {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .zoom-image:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }