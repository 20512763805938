@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Hubballi:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Almarai", sans-serif;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.font {
  font-family: "Almarai", sans-serif;
  background-color: #f9f9f9;
}

.hieght-search {
  height: 80vh;
}

.logo {
  height: 46px;
  width: 50px;
}

.login-img {
  height: 20px;
  width: 20px;
}

.nav-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}
/* index.css or global.css */

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
}

body {
  font-family: 'Hubballi', sans-serif;
}

/* Ensure the main container takes full width */
#root {
  width: 100%;
  overflow-x: hidden;
}
@tailwind base;
@tailwind components;
@tailwind utilities;