@font-face {
    font-family: 'Hubballi';
    font-style: normal;
    font-weight: 400;
    src: local('Hubballi'), url('https://fonts.cdnfonts.com/s/93158/Hubballi-Regular.woff') format('woff');
}

.register-container {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    padding: 29px;
    text-align: center;
}

.register-title {
    font-family: 'Hubballi', sans-serif;
    font-size: 2.2rem; /* Adjusted for responsiveness */
    font-weight: normal;
    letter-spacing: 3px;
    margin-bottom: 1.5rem;
}

.register-subtitle {
    font-family: 'Hubballi', sans-serif;
    font-size: 1.2rem; /* Adjusted for responsiveness */
    margin-bottom: 1.5rem;
    font-weight: normal;
}

.register-form {
    display: flex;
    flex-direction: column;
}

.register-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border: 0.5px solid #ccc;
    border-radius: 4px; /* Slightly rounded corners */
    font-size: 1rem; /* Adjusted for responsiveness */
    font-family: Georgia, serif;
    position: relative;
}

.password-container {
    position: relative;
}

.password-toggle {
    position: absolute;
    right: 10px;
    /* top: 50%; */
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

.register-button {
    padding: 15px; /* Reduced padding for better responsiveness */
    font-family: 'Hubballi', sans-serif;
    font-size: 1.1rem; /* Adjusted for responsiveness */
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px; /* Slightly rounded corners */
    cursor: pointer;
    letter-spacing: 1px; /* Reduced letter-spacing for better readability */
    margin-top: 1.5rem;
}

.login-account {
    font-size: 0.9rem; /* Adjusted for responsiveness */
    font-family: Arial, serif;
    font-weight: lighter;
    margin-top: 1.5rem;
}

.login-account a {
    color: #000;
    text-decoration: none;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .register-title {
        font-size: 1.8rem; /* Smaller title on medium screens */
    }

    .register-subtitle {
        font-size: 1rem; /* Smaller subtitle on medium screens */
    }

    .register-button {
        font-size: 1rem; /* Smaller button text on medium screens */
    }

    .login-account {
        font-size: 0.8rem; /* Smaller font size on medium screens */
    }
}

@media (max-width: 480px) {
    .register-title {
        font-size: 1.5rem; /* Smaller title on small screens */
    }

    .register-subtitle {
        font-size: 0.9rem; /* Smaller subtitle on small screens */
    }

    .register-button {
        font-size: 0.9rem; /* Smaller button text on small screens */
        padding: 10px; /* Smaller padding on small screens */
    }

    .login-account {
        font-size: 0.7rem; /* Smaller font size on small screens */
    }
}
